var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":_vm.id,"title":_vm.title,"scrollable":"","centered":"","hide-header-close":"","no-close-on-backdrop":"","no-enforce-focus":"","hide-footer":"","modal-class":"vi-main-modal vi-b-modal","header-class":"vi-b-modal--header","body-class":"vi-b-modal--body","footer-class":"vi-b-modal--footer"}},[_c('form',{staticClass:"vi-form",style:(_vm.styleObject),on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('p',{staticClass:"vi-text-secondary"},[_vm._v("\n      "+_vm._s(_vm.$t("wl_add_your_mobile_number"))+"\n    ")]),_c('div',{staticClass:"vi-form--body"},[_c('div',{staticClass:"form-group"},[_c('span',{staticClass:"vi-form-label",attrs:{"data-end":"*"}},[_vm._v(_vm._s(_vm.$t("reg_customer_phone")))]),_c('vue-tel-input',{class:`vi-form-control ${
            !!_vm.errors.phoneIsNotValid && ' vi-form-control--error'
          }`,attrs:{"input-options":{ placeholder: _vm.$t('phone') },"name":"phone"},on:{"input":_vm.validatePhone},model:{value:(_vm.values.phone),callback:function ($$v) {_vm.$set(_vm.values, "phone", $$v)},expression:"values.phone"}}),(!!_vm.errors.phoneIsNotValid)?_c('p',{staticClass:"vi-form-control--text-error"},[_vm._v("\n          "+_vm._s(_vm.errors.phoneIsNotValid)+"\n        ")]):_vm._e()],1),(_vm.enablePolicy > 0)?_c('div',{staticClass:"pt-2 pb-2"},[_c('div',{staticClass:"vi-form-group"},[_c('Checkbox',{staticClass:"checkbox-secondary",class:`${
              !!_vm.errors.checkboxLeagalAge && ' vi-checkbox-default-error'
            }`,attrs:{"checked":_vm.values.checkboxLeagalAge,"text":_vm.$t('confirm_age')},on:{"change":(val) => (_vm.values.checkboxLeagalAge = val),"validate":function($event){return _vm.validate('checkboxLeagalAge')}}})],1),_c('span',{staticClass:"vi-link-primary mb-3",on:{"click":_vm.openPrivacyPolice}},[_vm._v(_vm._s(_vm.$t("privacy_policy")))]),_c('div',{staticClass:"vi-form-group"},[_c('Checkbox',{staticClass:"checkbox-secondary",attrs:{"checked":_vm.checkboxEmailContact,"text":_vm.$t('i_agree_contact_by_email')},on:{"change":(val) => (_vm.checkboxEmailContact = val)}})],1),_c('div',{staticClass:"vi-form-group"},[(_vm.marketSms)?_c('Checkbox',{staticClass:"checkbox-secondary",attrs:{"checked":_vm.checkboxSmsContact,"text":_vm.$t('i_agree_contact_by_sms')},on:{"change":(val) => (_vm.checkboxSmsContact = val)}}):_vm._e()],1),_c('div',{staticClass:"vi-form-group"},[(_vm.medicalConsent)?_c('Checkbox',{staticClass:"checkbox-secondary",attrs:{"checked":_vm.checkboxMedicalContact,"text":_vm.$t('i_agree_to_medical_related_data_to_be_stored')},on:{"change":(val) => (_vm.checkboxMedicalContact = val)}}):_vm._e()],1)]):_vm._e()]),_c('div',{staticClass:"vi-form--footer"},[_c('button',{staticClass:"vi-btn btn-primary btn-full",class:_vm.loadingButton
            ? 'btn-loader'
            : _vm.values.phone.length < 4
            ? 'btn-disabled'
            : '',attrs:{"disabled":_vm.loadingButton,"type":"submit"}},[_vm._v("\n        "+_vm._s(_vm.$t("save"))+"\n\n        "),(_vm.loadingButton)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1)])]),_c('Modal',{attrs:{"id":_vm.idModalPolice,"title":_vm.titleModalPolice}},[_c('div',{staticClass:"vi-typography pt-2 pb-2"},[_c('p',[_vm._v(_vm._s(_vm.privacyPolicy))])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
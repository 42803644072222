import Vue from "vue";
import Vuex from "vuex";

import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";
import data from "./data.json";

import { vouchersModule } from "./modules/vouchers";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // Settings
    widgetOptions: {
      isWidgetModal: false,
      controlsOverlay: false,
      paddingControlsBottom: 0
    },
    previewInformation: {},

    // Timer
    timer: {
      status: false,
      countdown: 300
    },

    // Loaders
    loading: false,
    loadingApp: false,
    loadingMore: false,
    loadingButton: false,
    loadingGoogle: false,

    // Steps
    pastUrl: null,
    loginFromPath: null,

    authHeaderReady: false,
    salonId: null,
    mainLogoIsHide: false,
    salon: {},
    language: "",
    timeZone: "Pacific/Tongatapu",
    isShowFormWizard: true,
    showGoogleLoginButton: false,
    isLoggedCustomer: false,

    // Services
    services: [],
    servicesFiltered: [],
    servicesBasket: [],
    savedServices: [],
    chosenServices: [],
    chosenServicesData: [],
    totalServicesPrice: 0,
    totalServicesPriceString: null,
    totalServicesPriceFrom: false,
    totalServicesTime: 0,
    lastServices: {
      future: [],
      past: []
    },

    // Calendar
    availableSlots: {
      slotsData: {},
      time: {
        morning: [],
        afternoon: [],
        evening: []
      },
      error: null
    },
    nextAvailableSlots: {
      slotsData: {},
      time: {
        morning: [],
        afternoon: [],
        evening: []
      },
      error: null
    },
    nextAvailableSlotsDate: "",
    isLoadingNextAvailableSlots: false,
    chosenSlot: {
      date: "",
      time: "",
      slots: []
    },
    errorSlot: {
      status: false,
      message: ""
    },
    globalDateSlots: null,

    // Employees
    venueEmployees: [],
    venueEmployeeTypes: [],
    filteredEmployees: [],
    isOtherEmployees: false,
    selectedEmployeeByQuery: null,

    limitBooking: {
      status: false,
      message: ""
    },

    isShowPasswordForgotModal: false,
    staff: [],
    time: [],
    isLoggedIn: false,
    staff_filter: data.staff_filter,
    selectedStaff: "",
    selectedAdditionalStaff: "",
    isPastAppointment: false,
    futureAppointments: null,
    pastAppointments: null,
    futureAppointmentsData: [],
    pastAppointmentsData: [],
    serviceError: false,
    serviceErrorName: "",
    serviceFilters: [],
    serviceStaffFilters: [],
    cardData: {},
    bookingNote: "",
    errorSalonLoad: false,
    venuePricing: [],
    customer: null,
    isCustomerPhoneConfirmed: false,

    // Payment
    payment_option: "appointment", // 'appointment' | 'voucher'
    deposit: 0,
    selectedPaymentVariant: null,
    paymentIntent: null,
    totalPriceBooking: null,

    selectedIds: {
      serviceId: null,
      employeeId: null,
      voucherId: null
    }
  },
  getters,
  mutations,
  actions,
  modules: {
    vouchersModule
  }
});

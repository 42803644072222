var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',[_c('div',{staticClass:"vi-main-wrapper--inner"},[(
        _vm.getStableChosenServices.length === 1 &&
        !_vm.getStableChosenServices[0].selectedEmployee
      )?_c('div',{staticClass:"vi-employees-list"},[_c('div',{staticClass:"vi-card-employee",on:{"click":function($event){_vm.getChosenServicesData[0].employee_id !== 0
            ? _vm.chooseEmployee(
                _vm.getChosenServices[0].id,
                0,
                _vm.$t('first_available_specialist')
              )
            : _vm.removeEmployee(_vm.getChosenServices[0].service_id, 0)}}},[_c('div',{staticClass:"vi-card-employee--inner"},[_c('div',{staticClass:"vi-card-employee--info"},[_c('div',{staticClass:"vi-card-employee--info-img"},[_c('img',{attrs:{"src":_vm.imageUrlWrapper(_vm.firstImgLink),"alt":"staff-avatar"}})]),_c('div',{staticClass:"vi-card-employee--info-wrap"},[_c('p',{staticClass:"name"},[_vm._v(_vm._s(_vm.$t("first_available_specialist")))]),_c('span',{staticClass:"position"},[_vm._v(_vm._s(_vm.$t("maximum_availability")))])])]),_c('div',{staticClass:"vi-card-employee--btn"},[(_vm.getChosenServicesData[0].employee_id !== 0)?_c('span',{staticClass:"vi-btn-add"}):_c('span',{staticClass:"vi-btn-add added"})])])]),_vm._l((_vm.getStableChosenServices[0].employees),function(staff){return _c('div',{key:staff.id,staticClass:"vi-card-employee",on:{"click":function($event){_vm.getChosenServicesData[0].employee_id !== staff.id
            ? _vm.chooseEmployee(
                _vm.getChosenServices[0].id,
                staff.id,
                staff.first_name + ' ' + staff.last_name
              )
            : _vm.removeEmployee(_vm.getChosenServices[0].service_id, staff.id)}}},[_c('div',{staticClass:"vi-card-employee--inner"},[_c('div',{staticClass:"vi-card-employee--info"},[_c('div',{staticClass:"vi-card-employee--info-img"},[(staff.img === '')?_c('img',{attrs:{"src":_vm.imageUrlWrapper(_vm.firstImgLink),"alt":"staff-avatar"}}):_c('img',{attrs:{"src":`${staff.img}`,"alt":"staff-avatar"}})]),_c('div',{staticClass:"vi-card-employee--info-wrap"},[_c('p',{staticClass:"name"},[_vm._v(_vm._s(staff.first_name)+" "+_vm._s(staff.last_name))]),_c('span',{staticClass:"position"},[_vm._v(_vm._s(_vm.getSalonEmployeeTypes.find(
                  (x) => Number(x.id) === Number(staff.employee_type)
                ).title))])])]),_c('div',{staticClass:"vi-card-employee--btn"},[(_vm.getChosenServicesData[0].employee_id !== staff.id)?_c('span',{staticClass:"vi-btn-add"}):_c('span',{staticClass:"vi-btn-add added"})])])])})],2):_c('div',{staticClass:"vi-appointment"},[_c('div',{staticClass:"vi-appointments-list"},[_vm._l((_vm.getStableChosenPackages),function(packageItem){return _c('div',{key:packageItem.id,staticClass:"vi-group-package"},[_c('div',{staticClass:"vi-group-package__header"},[_c('span',{staticClass:"vi-group-package__header-title"},[_vm._v(_vm._s(packageItem.title))]),_c('span',{staticClass:"vi-card-appointment--wrap-close",on:{"click":function($event){return _vm.removePackage({
                  package_id: packageItem.id,
                  total_price: packageItem.total_price,
                  total_duration: packageItem.total_duration
                })}}},[_c('i',{staticClass:"ic-close"})])]),_c('div',{staticClass:"vi-group-package__body"},_vm._l((packageItem.services),function(item){return _c('div',{key:item.id + item.variants[0].id,staticClass:"vi-card-appointment"},[_c('div',{staticClass:"vi-card-appointment--wrap"},[_c('h6',{staticClass:"vi-card-appointment--wrap-title"},[_vm._v("\n                  "+_vm._s(item.title)+"\n                  "),(item.variants[0].caption)?_c('small',[_vm._v("("+_vm._s(item.variants[0].caption)+")")]):_vm._e()]),_c('p',{staticClass:"vi-card-appointment--wrap-subtitle"},[_vm._v("\n                  "+_vm._s(item.group_title)+"\n                ")]),_c('p',{staticClass:"vi-card-appointment--wrap-form",on:{"click":function($event){return _vm.addSelectedEmployees(
                      item.id,
                      item.employees,
                      item.variants[0].id,
                      item.variants.length === 1 ? 'multiple' : 'single'
                    )}}},[_c('span',{staticClass:"vi-card-appointment--wrap-form--input"},[(!item.selectedEmployee)?_c('span',{staticClass:"placeholder"},[_vm._v(_vm._s(_vm.$t("choose_staff")))]):_c('span',[_vm._v(_vm._s(item.selectedEmployee))])]),_c('img',{attrs:{"src":_vm.imageUrlWrapper(_vm.arrowRightImg),"alt":"arrowRightImg"}})])])])}),0)])}),_vm._l((_vm.getStableChosenServices),function(item){return (!item.package_id)?_c('div',{key:item.id + item.variants[0].id,staticClass:"vi-card-appointment"},[_c('div',{staticClass:"vi-card-appointment--wrap"},[_c('span',{staticClass:"vi-card-appointment--wrap-close",on:{"click":function($event){return _vm.removeServices(item)}}},[_c('i',{staticClass:"ic-close"})]),_c('h6',{staticClass:"vi-card-appointment--wrap-title"},[_vm._v("\n              "+_vm._s(item.title)+"\n              "),(item.variants[0].caption)?_c('small',[_vm._v("("+_vm._s(item.variants[0].caption)+")")]):_vm._e()]),_c('p',{staticClass:"vi-card-appointment--wrap-subtitle"},[_vm._v("\n              "+_vm._s(item.group_title)+"\n            ")]),_c('p',{staticClass:"vi-card-appointment--wrap-form",on:{"click":function($event){return _vm.addSelectedEmployees(
                  item.id,
                  item.employees,
                  item.variants[0].id,
                  item.variants.length === 1 ? 'multiple' : 'single'
                )}}},[_c('span',{staticClass:"vi-card-appointment--wrap-form--input"},[(!item.selectedEmployee)?_c('span',{staticClass:"placeholder"},[_vm._v(_vm._s(_vm.$t("choose_staff")))]):_c('span',[_vm._v(_vm._s(item.selectedEmployee))])]),_c('img',{attrs:{"src":_vm.imageUrlWrapper(_vm.arrowRightImg),"alt":"arrowRightImg"}})])])]):_vm._e()})],2),_c('div',{staticClass:"vi-appointment-controls",class:_vm.controlsOverlay ? 'inside-controls' : ''},[_c('div',{staticClass:"vi-appointment-controls--fix-width"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-12"},[_c('button',{staticClass:"vi-btn btn-primary btn-full",class:_vm.getChosenServicesData.find(
                    (item) => item.employee_id === undefined
                  )
                    ? 'btn-disabled'
                    : '',attrs:{"disabled":_vm.getChosenServicesData.find(
                    (item) => item.employee_id === undefined
                  ),"type":"button"},on:{"click":function($event){return _vm.getThirdStep(_vm.getChosenServicesData)}}},[_vm._v("\n                "+_vm._s(_vm.$t("choose_date_and_time"))+"\n              ")])])])])])]),_c('Modal',{attrs:{"id":_vm.idModal,"title":_vm.titleModal}},[_c('div',{staticClass:"vi-employees-list"},[_c('div',{staticClass:"vi-card-employee"},[(_vm.pricingType === 'multiple')?_c('span',{staticClass:"vi-card-employee--click",on:{"click":function($event){_vm.getChosenServicesData.find(
                (item) =>
                  item.variant_id === _vm.selectedVariantId &&
                  item.employee_id === 0
              )
                ? _vm.removeEmployeeByVariant(_vm.selectedVariantId, 0)
                : _vm.chooseMultiEmployeeByVariant(
                    _vm.selectedVariantId,
                    0,
                    _vm.$t('first_available_specialist')
                  )}}}):_c('span',{staticClass:"vi-card-employee--click",on:{"click":function($event){_vm.getChosenServicesData.find(
                (item) =>
                  item.service_id === _vm.selectedServiceId &&
                  item.employee_id === 0
              )
                ? _vm.removeEmployee(_vm.selectedServiceId, 0)
                : _vm.chooseMultiEmployee(
                    _vm.selectedServiceId,
                    0,
                    _vm.$t('first_available_specialist')
                  )}}}),_c('div',{staticClass:"vi-card-employee--inner"},[_c('div',{staticClass:"vi-card-employee--info"},[_c('div',{staticClass:"vi-card-employee--info-img"},[_c('img',{attrs:{"src":_vm.imageUrlWrapper(_vm.firstImgLink),"alt":"staff-avatar"}})]),_c('div',{staticClass:"vi-card-employee--info-wrap"},[_c('p',{staticClass:"name"},[_vm._v(_vm._s(_vm.$t("first_available_specialist")))]),_c('span',{staticClass:"position"},[_vm._v(_vm._s(_vm.$t("maximum_availability")))])])]),(_vm.pricingType === 'multiple')?_c('div',{staticClass:"vi-card-employee--btn"},[(
                  _vm.getChosenServicesData.find(
                    (item) =>
                      item.variant_id === _vm.selectedVariantId &&
                      item.employee_id === 0
                  )
                )?_c('span',{staticClass:"vi-btn-add added"}):_c('span',{staticClass:"vi-btn-add"})]):_c('div',{staticClass:"vi-card-employee--btn"},[(
                  _vm.getChosenServicesData.find(
                    (item) =>
                      item.service_id === _vm.selectedServiceId &&
                      item.employee_id === 0
                  )
                )?_c('span',{staticClass:"vi-btn-add added"}):_c('span',{staticClass:"vi-btn-add"})])])]),_vm._l((_vm.selectedStaff),function(staff){return _c('div',{key:staff.id,staticClass:"vi-card-employee"},[(_vm.pricingType === 'multiple')?_c('span',{staticClass:"vi-card-employee--click",on:{"click":function($event){_vm.getChosenServicesData.find(
                (item) =>
                  item.variant_id === _vm.selectedVariantId &&
                  item.employee_id === staff.id
              )
                ? _vm.removeEmployeeByVariant(_vm.selectedVariantId, staff.id)
                : _vm.chooseMultiEmployeeByVariant(
                    _vm.selectedVariantId,
                    staff.id,
                    _vm.stickName(staff.first_name, staff.last_name)
                  )}}}):_c('span',{staticClass:"vi-card-employee--click",on:{"click":function($event){_vm.getChosenServicesData.find(
                (item) =>
                  item.service_id === _vm.selectedServiceId &&
                  item.employee_id === staff.id
              )
                ? _vm.removeEmployee(_vm.selectedServiceId, staff.id)
                : _vm.chooseMultiEmployee(
                    _vm.selectedServiceId,
                    staff.id,
                    _vm.stickName(staff.first_name, staff.last_name)
                  )}}}),_c('div',{staticClass:"vi-card-employee--inner"},[_c('div',{staticClass:"vi-card-employee--info"},[_c('div',{staticClass:"vi-card-employee--info-img"},[(staff.img === '')?_c('img',{attrs:{"src":_vm.imageUrlWrapper(_vm.firstImgLink),"alt":"staff-avatar"}}):_c('img',{attrs:{"src":`${staff.img}`,"alt":"staff-avatar"}})]),_c('div',{staticClass:"vi-card-employee--info-wrap"},[_c('p',{staticClass:"name"},[_vm._v("\n                  "+_vm._s(staff.first_name)+" "+_vm._s(staff.last_name)+"\n                ")]),_c('span',{staticClass:"position"},[_vm._v(_vm._s(_vm.getSalonEmployeeTypes.find(
                    (x) => Number(x.id) === Number(staff.employee_type)
                  ).title))])])]),(_vm.pricingType === 'multiple')?_c('div',{staticClass:"vi-card-employee--btn"},[(
                  _vm.getChosenServicesData.find(
                    (item) =>
                      item.variant_id === _vm.selectedVariantId &&
                      item.employee_id === staff.id
                  )
                )?_c('span',{staticClass:"vi-btn-add added"}):_c('span',{staticClass:"vi-btn-add"})]):_c('div',{staticClass:"vi-card-employee--btn"},[(
                  _vm.getChosenServicesData.find(
                    (item) =>
                      item.service_id === _vm.selectedServiceId &&
                      item.employee_id === staff.id
                  )
                )?_c('span',{staticClass:"vi-btn-add added"}):_c('span',{staticClass:"vi-btn-add"})])])])})],2)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
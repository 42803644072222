<template>
  <div v-if="!salonIsPremium && showHeader" class="vi-header-no-premium">
    <div class="container">
      <div class="vi-header-no-premium__inner">
        <div class="vi-header-no-premium__logo">
          <img :src="imageUrlWrapper(img)" />
        </div>
        <div class="vi-header-no-premium__name">
          <p
            v-if="!widgetOptions.previewVersion"
            class="vi-header-no-premium__name-text"
          >
            {{ salonName }}
          </p>
          <p v-else class="vi-header-no-premium__name-text">
            {{
              previewInformation.settings && previewInformation.settings.title
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import imgLink from "../../assets/img/logo-white.svg";
import { imageWrapper } from "../../utils/common/common.js";

export default {
  data() {
    return {
      showHeader: true,
      img: imgLink
    };
  },
  computed: {
    ...mapGetters([
      "salonName",
      "salonIsPremium",
      "widgetOptions",
      "previewInformation"
    ])
  },
  watch: {
    $route(to) {
      this.showHeader = to.name !== "home";
      if (this.previewInformation.settings && this.$route.name !== "home") {
        this.showHeader = !this.previewInformation.settings.is_premium;
      }
    }
  },
  mounted() {
    this.showHeader = this.$route.name !== "home";
    if (this.previewInformation.settings && this.$route.name !== "home") {
      this.showHeader = !this.previewInformation.settings.is_premium;
    }
  },
  methods: {
    imageUrlWrapper(url) {
      return imageWrapper(url);
    }
  }
};
</script>

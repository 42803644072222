var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vi-card-basket"},[_c('div',{staticClass:"vi-card-basket--header"},[_c('div',{staticClass:"vi-card-basket--header-date"},[_c('span',[_vm._v(" "+_vm._s(_vm.date)+", "+_vm._s(_vm.time)+" ")])]),_c('div',{staticClass:"vi-card-basket--header-time"},[_c('vi-timer')],1),_c('div',{staticClass:"vi-card-basket--header-address"},[_c('span',[_vm._v(_vm._s(_vm.salon)+", "+_vm._s(_vm.address))])])]),(!_vm.isLoggedCustomer)?_c('div',{staticClass:"vi-card-basket--body"},[_vm._l((_vm.packages ? _vm.packages : _vm.getStableServices.packages),function(packageItem){return _c('div',{key:packageItem.id,staticClass:"vi-card-basket-package"},[_c('span',{staticClass:"vi-card-basket-finish--info-remove",on:{"click":function($event){return _vm.removePackage({
            package_id: packageItem.id,
            total_price: packageItem.total_price,
            total_duration: packageItem.total_duration
          })}}},[_c('i',{staticClass:"ic-close"})]),_c('div',{staticClass:"vi-card-basket-package__header"},[_c('span',{staticClass:"vi-card-basket-package__header-title"},[_vm._v(_vm._s(packageItem.title))]),_c('div',{staticClass:"vi-card-basket-package__header-prices"},[_c('span',{staticClass:"duration"},[_vm._v(_vm._s(_vm._f("timeFormat")(packageItem.total_duration)))]),_c('span',{staticClass:"price"},[_vm._v(_vm._s(_vm._f("defaultPriceFormat")(packageItem.total_price,_vm.getPriceFormat)))])])]),_c('div',{staticClass:"vi-card-basket-package__body"},_vm._l((packageItem.services),function(service){return _c('div',{key:service.id,staticClass:"vi-card-basket-finish"},[_c('div',{staticClass:"vi-card-basket-finish--caption"},[_c('h3',[_vm._v(_vm._s(service.title))]),_c('div',{staticClass:"prices"},[(
                  service.variants[0].custom_price &&
                  service.variants[0].custom_price !==
                    service.variants[0].price
                )?_c('span',{staticClass:"old-price"},[_vm._v(_vm._s(service.variants[0].price_formatted))]):_vm._e(),_c('span',{staticClass:"price"},[_vm._v(_vm._s(_vm._f("defaultPriceFormat")(service.variants[0].custom_price
                  ? service.variants[0].custom_price
                  : service.variants[0].price,_vm.getPriceFormat)))])])]),_c('div',{staticClass:"vi-card-basket-finish--info"},[_c('div',[_c('span',{staticClass:"vi-card-basket-finish--info-employee"},[_vm._v("\n                "+_vm._s(service.employee.first_name)+"\n                "+_vm._s(service.employee.last_name)+"\n              ")]),_c('span',{staticClass:"vi-card-basket-finish--info-time"},[_c('i',{staticClass:"ic-vi-clock"}),_vm._v(_vm._s(service.time_formatted)+" ("+_vm._s(_vm._f("timeFormat")(service.variants[0].custom_duration
                    ? service.variants[0].custom_duration
                    : service.variants[0].duration))+")\n              ")])])])])}),0)])}),_vm._l((_vm.data ? _vm.data : _vm.getStableServices.services),function(item){return (!item.package_id)?_c('div',{key:item.id,staticClass:"vi-card-basket--body-item"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('h3',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('span',{staticClass:"price"},[_vm._v(_vm._s(item.variants[0].price_formatted))])]),_c('span',{staticClass:"title-group"},[_vm._v(_vm._s(item.group_title))]),_c('div',{staticClass:"d-flex justify-content-between"},[_c('p',{staticClass:"time"},[_vm._v("\n          "+_vm._s(_vm._f("timeFormat")(item.variants[0].duration))+" |\n          "+_vm._s(item.employee.first_name)+" "+_vm._s(item.employee.last_name)+"\n        ")]),_c('span',{staticClass:"vi-card-basket--delete",on:{"click":function($event){return _vm.removeService(item)}}},[_c('i',{staticClass:"ic-close"})])])]):_vm._e()})],2):_vm._e(),(!_vm.isLoggedCustomer)?_c('div',{staticClass:"vi-card-basket--footer"},[_c('span',{staticClass:"vi-card-basket--footer-label"},[_vm._v(_vm._s(_vm.$t("total_to_pay")))]),_c('span',{staticClass:"vi-card-basket--footer-price"},[_vm._v("\n      "+_vm._s(_vm._f("priceFormat")(_vm.getTotalServicesPrice,_vm.getPriceFormat, _vm.getChosenServices))+"\n    ")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="vi-card-accordion">
    <div
      :class="isShow ? null : 'collapsed'"
      :aria-expanded="isShow ? 'true' : 'false'"
      class="vi-card-accordion--heading"
      aria-controls="collapse-4"
      @click="onToggle(id)"
    >
      <h6 class="title-heading">{{ title }}</h6>
      <i :class="isShow ? 'collapsed' : ''" class="ic-vi-arr-bottom" />
    </div>
    <div class="vi-card-accordion--body">
      <b-collapse id="collapse-4" v-model="isShow">
        <div
          v-for="service in services"
          :key="service.id"
          :ref="`service${service.id}`"
          :class="
            service.booking === 'bookable_phone'
              ? 'vi-card-accordion-disabled'
              : ''
          "
          class="vi-card-accordion--item"
        >
          <div v-if="service.serviceType === 'multiple'">
            <div
              :class="[
                service.isShowVariants ? null : 'collapsed',
                service.disabled ? 'pkg_disabled' : '',
                'vi-card-accordion--item-inner'
              ]"
              :aria-expanded="service.isShowVariants ? 'true' : 'false'"
              aria-controls="collapse-4"
            >
              <div
                class="vi-card-accordion--item-inner--toggle"
                @click="
                  service.disabled ? '' : onToggleVariants(service.service_id)
                "
              ></div>
              <div
                :class="
                  getChosenServices.find((item) =>
                    getIdVariant(item.variants[0].id, service.variants)
                  )
                    ? 'active'
                    : ''
                "
                class="vi-card-accordion--item-caption-btn"
              >
                <span
                  :class="
                    service.isShowVariants
                      ? 'vi-btn-arrow rotate-up'
                      : 'vi-btn-arrow'
                  "
                />
              </div>
              <div
                :class="service.disabled ? 'pkg_disabled' : ''"
                class="vi-card-accordion--item-caption"
              >
                <div class="vi-card-accordion--item-caption--head">
                  <h5>
                    {{ service.title }}
                    <span
                      v-if="service.required_additional_staff === 1"
                      class="second-staff_info"
                      >{{ $t("2_staff_required") }}</span
                    >
                  </h5>
                  <p class="vi-price">
                    {{
                      $t("from")
                        | filteredSmallPrice(
                          service.variants,
                          service.variants[0].price_formatted,
                          service.booking,
                          getPriceFormat
                        )
                    }}
                  </p>
                </div>
                <div class="vi-card-accordion--item-caption--body">
                  <div>
                    <p class="vi-card-accordion--item-caption--time">
                      {{ service.variants[0].duration }}
                      {{ $t("minute_short") }} -
                      {{
                        service.variants[service.variants.length - 1].duration
                      }}
                      {{ $t("minute_short") }}
                    </p>
                    <span
                      v-if="service.description"
                      class="vi-card-accordion--item-caption--more"
                      @click="
                        showDetails({
                          ...service,
                          price: methodSmallPrice(
                            $t('from'),
                            service.variants,
                            service.variants[0]
                              ? service.variants[0].price_formatted
                              : '',
                            service.booking,
                            getPriceFormat
                          ),
                          time: `${
                            service.variants[0]
                              ? service.variants[0].duration
                              : ''
                          } ${$t('minute_short')} - ${
                            service.variants[service.variants.length - 1]
                              .duration
                          } ${$t('minute_short')}`
                        })
                      "
                      >{{ $t("show_details") }}</span
                    >
                  </div>
                  <span
                    v-if="service.booking === 'bookable_phone'"
                    class="light-label"
                    >{{ $t("book_by_phone_only") }}</span
                  >
                </div>
              </div>
            </div>
            <b-collapse id="collapse-4" v-model="service.isShowVariants">
              <div
                v-for="variant in service.variants"
                :key="variant.id"
                :ref="`variant${variant.id}`"
                class="vi-card-accordion--item"
              >
                <div
                  class="vi-card-accordion--item-inner"
                  @click="
                    selectVariant(service, variant, title, getChosenServices)
                  "
                >
                  <div class="vi-card-accordion--item-caption-btn">
                    <span
                      v-if="
                        getChosenServices.find(
                          (item) => item.variants[0].id === variant.id
                        )
                      "
                      class="vi-btn-add btn-add-line added"
                    />
                    <span v-else class="vi-btn-add btn-add-line" />
                  </div>
                  <div class="vi-card-accordion--item-caption">
                    <div class="vi-card-accordion--item-caption--head">
                      <h5>{{ variant.caption }}</h5>
                      <span class="vi-price">
                        {{ variant.price_formatted }}
                      </span>
                    </div>
                    <div class="vi-card-accordion--item-caption--body">
                      <p class="vi-card-accordion--item-caption--time">
                        {{ variant.duration }} {{ $t("minute_short") }}
                      </p>
                      <span
                        v-if="service.booking === 'bookable_phone'"
                        class="light-label"
                        >{{ $t("book_by_phone_only") }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
          <div
            v-else
            :class="[
              service.booking === 'bookable_phone'
                ? 'vi-card-accordion-brake-event'
                : '',
              service.disabled ? 'pkg_disabled' : ''
            ]"
            class="vi-card-accordion--item-inner"
          >
            <div
              class="vi-card-accordion--item-inner--toggle"
              @click="
                service.disabled
                  ? ''
                  : selectService(service, title, getChosenServices)
              "
            ></div>
            <div class="vi-card-accordion--item-caption-btn">
              <span
                v-if="
                  getChosenServices.find(
                    (item) =>
                      item.id === service.id &&
                      item.variants[0].id === service.variants[0].id &&
                      !item.package_id
                  )
                "
                class="vi-btn-add added"
              />
              <span v-else class="vi-btn-add" />
            </div>
            <div class="vi-card-accordion--item-caption">
              <div class="vi-card-accordion--item-caption--head">
                <h5>
                  {{ service.title }}
                  <span
                    v-if="service.required_additional_staff === 1"
                    class="second-staff_info"
                    >{{ $t("2_staff_required") }}</span
                  >
                </h5>
                <span class="vi-price">
                  {{
                    service.variants[0]
                      ? service.variants[0].price_formatted
                      : ""
                  }}
                </span>
              </div>
              <div class="vi-card-accordion--item-caption--body">
                <div>
                  <p class="vi-card-accordion--item-caption--time">
                    {{
                      service.variants[0] ? service.variants[0].duration : ""
                    }}
                    {{ $t("minute_short") }} |
                    {{ service.group_title }}
                  </p>
                  <span
                    v-if="service.description || service.service.image"
                    class="vi-card-accordion--item-caption--more"
                    @click="
                      showDetails({
                        ...service,
                        price: service.variants[0]
                          ? service.variants[0].price_formatted
                          : '',
                        time: `${
                          service.variants[0]
                            ? service.variants[0].duration
                            : ''
                        } ${$t('minute_short')}`
                      })
                    "
                    >{{ $t("show_details") }}</span
                  >
                </div>
                <span
                  v-if="service.booking === 'bookable_phone'"
                  class="light-label"
                  >{{ $t("book_by_phone_only") }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import imgArrow from "../../assets/img/arrow-down-round.svg";
import { imageWrapper, priceFormatter } from "../../utils/common/common";

export default {
  filters: {
    filteredSmallPrice(from, arr, text, type, format) {
      if (type === "hide_price") {
        return text;
      } else {
        const smaller = arr.reduce((a, b) => {
          if (a.price !== 0 && b.price !== 0) {
            if (a.price < b.price) {
              return a;
            }
            return b;
          }
          return a;
        });

        return `${from} ${priceFormatter(smaller.price, format)}`;
      }
    }
  },
  props: [
    "id",
    "title",
    "isShow",
    "services",
    "chooseService",
    "chooseVariant"
  ],
  data() {
    return {
      imgArrow,
      itemRefs: []
    };
  },
  computed: {
    ...mapGetters(["getServices", "getChosenServices", "getPriceFormat"])
  },
  methods: {
    ...mapMutations(["setIsShowCollapse", "setIsShowVariants"]),
    methodSmallPrice(from, arr, text, type, format) {
      if (type === "hide_price") {
        return text;
      } else {
        const smaller = arr.reduce((a, b) => {
          if (a.price !== 0 && b.price !== 0) {
            if (a.price < b.price) {
              return a;
            }
            return b;
          }
          return a;
        });

        return `${from} ${priceFormatter(smaller.price, format)}`;
      }
    },
    selectService(service, title, arr) {
      this.chooseService(
        service,
        title,
        arr,
        this.$refs[`service${service.id}`][0].offsetTop
      );
    },
    selectVariant(service, variant, title, arr) {
      this.chooseVariant(
        service,
        variant,
        title,
        arr,
        this.$refs[`variant${variant.id}`][0].offsetTop
      );
    },
    // chooseService(service, title, arr) {
    //   arr.find(
    //     (item) =>
    //       item.id === service.id &&
    //       item.variants[0].id === service.variants[0].id
    //   )
    //     ? this.removeServices(service)
    //     : this.addServices({
    //         ...service,
    //         titleGroup: title,
    //         positionY: this.$refs[`service${service.id}`][0].offsetTop
    //       });
    // },
    // chooseServiceByVariant(service, variant, title, arr) {
    //   arr.find((item) => item.variants[0].id === variant.id)
    //     ? this.removeServicesByVariant({
    //         ...variant,
    //         type: service.booking
    //       })
    //     : this.addServices({
    //         ...service,
    //         variants: [variant],
    //         variantDuration: variant.duration,
    //         variantPrice: variant.price_formatted,
    //         titleGroup: title,
    //         positionY: this.$refs[`variant${variant.id}`][0].offsetTop
    //       });
    // },
    getIdVariant(id, arr) {
      let flag = false;
      arr.map((item) => {
        if (id === item.id) {
          flag = true;
        }
      });
      if (flag) {
        return id;
      }
    },
    imageUrlWrapper(url) {
      return imageWrapper(url);
    },
    onToggle(id) {
      this.setIsShowCollapse(id);
    },
    onToggleVariants(id) {
      this.setIsShowVariants(id);
    },
    showDetails(info) {
      this.$emit("show-details", { ...info, isShow: true });
    }
  }
};
</script>

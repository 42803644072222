var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vi-card-accordion"},[_c('div',{staticClass:"vi-card-accordion--heading",class:_vm.isShow ? null : 'collapsed',attrs:{"aria-expanded":_vm.isShow ? 'true' : 'false',"aria-controls":"collapse-5"},on:{"click":function($event){return _vm.onToggle(_vm.id)}}},[_c('h6',{staticClass:"title-heading"},[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")]),_c('i',{staticClass:"ic-vi-arr-bottom",class:_vm.isShow ? 'collapsed' : ''})]),_c('div',{staticClass:"vi-card-accordion--body"},[_c('b-collapse',{attrs:{"id":"collapse-5"},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},_vm._l((_vm.packages),function(packageItem){return _c('div',{key:packageItem.id,ref:`package${packageItem.id}`,refInFor:true,staticClass:"vi-card-accordion--item",class:packageItem.disabled ? 'pkg_disabled' : ''},[_c('div',{staticClass:"vi-card-accordion--item-inner"},[_c('div',{staticClass:"vi-card-accordion--item-inner--toggle",on:{"click":function($event){packageItem.disabled
                ? ''
                : _vm.selectPackage(packageItem, _vm.title, _vm.getChosenServices)}}}),_c('div',{staticClass:"vi-card-accordion--item-caption-btn"},[(
                _vm.getChosenServices.find(
                  (item) =>
                    item.package_id && item.package_id === packageItem.id
                )
              )?_c('span',{staticClass:"vi-btn-add added"}):_c('span',{staticClass:"vi-btn-add"})]),_c('div',{staticClass:"vi-card-accordion--item-caption"},[_c('div',{staticClass:"vi-card-accordion--item-caption--head"},[_c('h5',[_vm._v("\n                "+_vm._s(packageItem.title)+" "+_vm._s(packageItem.disabled)+"\n                "),(
                    packageItem.services.some(
                      (i) => i.required_additional_staff === 1
                    )
                  )?_c('span',{staticClass:"second-staff_info"},[_vm._v(_vm._s(_vm.$t("2_staff_required")))]):_vm._e()]),_c('span',{staticClass:"vi-price"},[_vm._v("\n                "+_vm._s(_vm._f("formatPrice")(packageItem.total,_vm.getPriceFormat))+"\n              ")])]),_c('div',{staticClass:"vi-card-accordion--item-caption--body"},[_c('div',[_c('p',{staticClass:"vi-card-accordion--item-caption--time"},[_vm._v("\n                  "+_vm._s(packageItem.pricings
                      .map((i) => i.duration)
                      .reduce((a, b) => a + b))+"\n                  "+_vm._s(_vm.$t("minute_short"))+" |\n                  "+_vm._s(packageItem.services ? packageItem.services.length : 0)+"\n                  "+_vm._s(packageItem.services && packageItem.services.length === 1
                      ? _vm.$t("service")
                      : _vm.$t("services"))+"\n                ")]),_c('span',{staticClass:"vi-card-accordion--item-caption--more",on:{"click":function($event){return _vm.showDetails(packageItem)}}},[_vm._v(_vm._s(_vm.$t("show_details")))])])])])])])}),0)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
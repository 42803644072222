var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',[_c('div',{staticClass:"vi-main-wrapper--inner p-0"},[_c('div',{staticClass:"vi-booking-created"},[_c('div',{staticClass:"vi-booking-created--header"},[_c('img',{attrs:{"src":_vm.imageUrlWrapper(_vm.imgSuccess),"alt":"imgSuccess"}}),_c('h2',[_vm._v(_vm._s(_vm.$t("booking_created")))])]),_c('div',{staticClass:"vi-booking-created--body"},[_c('BasketCard',{attrs:{"date":_vm._f("filterDate")(_vm.getTimeOrder.date),"time":_vm.getTimeOrder.time_formatted,"salon":_vm.salonName,"address":_vm.salonAddress}}),_c('div',{staticClass:"vi-basket-list"},[_vm._l((_vm.getSavedServices.packages),function(packageItem){return _c('div',{key:packageItem.id,staticClass:"vi-card-basket-package"},[_c('div',{staticClass:"vi-card-basket-package__header"},[_c('span',{staticClass:"vi-card-basket-package__header-title"},[_vm._v("\n                "+_vm._s(packageItem.title)+"\n              ")]),_c('div',{staticClass:"vi-card-basket-package__header-prices"},[_c('span',{staticClass:"duration"},[_vm._v(_vm._s(_vm._f("timeFormat")(packageItem.total_duration)))]),_c('span',{staticClass:"price"},[_vm._v(_vm._s(_vm._f("defaultPriceFormat")(packageItem.total_price,_vm.getPriceFormat)))])])]),_c('div',{staticClass:"vi-card-basket-package__body"},_vm._l((packageItem.services),function(service){return _c('div',{key:service.id,staticClass:"vi-card-basket-finish"},[_c('div',{staticClass:"vi-card-basket-finish--caption"},[_c('h3',[_vm._v(_vm._s(service.title))]),_c('div',{staticClass:"prices"},[(
                        service.custom_price &&
                        service.custom_price !== service.price
                      )?_c('span',{staticClass:"old-price"},[_vm._v(_vm._s(service.price_formatted))]):_vm._e(),_c('span',{staticClass:"price"},[_vm._v(_vm._s(_vm._f("defaultPriceFormat")(service.custom_price
                        ? service.custom_price
                        : service.price,_vm.getPriceFormat)))])])]),_c('div',{staticClass:"vi-card-basket-finish--info"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"vi-card-basket-finish--info-employee"},[_c('i',{staticClass:"ic-vi-star-line"}),_vm._v("\n                      "+_vm._s(service.employee)+"\n                    ")]),_c('span',{staticClass:"vi-card-basket-finish--info-time"},[_c('i',{staticClass:"ic-vi-clock"}),_vm._v(_vm._s(service.time_formatted)+" ("+_vm._s(_vm._f("timeFormat")(service.custom_duration
                          ? service.custom_duration
                          : service.duration))+")\n                    ")])])])])}),0)])}),_vm._l((_vm.getSavedServices.services),function(item){return (!item.package_id)?_c('div',{key:item.id,staticClass:"vi-card-basket-finish"},[_c('div',{staticClass:"vi-card-basket-finish--caption"},[_c('h3',[_vm._v(_vm._s(item.title))]),_c('span',{staticClass:"price"},[_vm._v(_vm._s(item.priceFormatted))])]),_c('div',{staticClass:"vi-card-basket-finish--info"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"vi-card-basket-finish--info-employee"},[_c('i',{staticClass:"ic-vi-star-line"}),_vm._v("\n                  "+_vm._s(item.employee)+"\n                ")]),_c('span',{staticClass:"vi-card-basket-finish--info-time"},[_c('i',{staticClass:"ic-vi-clock"}),_vm._v(_vm._s(item.time_formatted)+" ("+_vm._s(_vm._f("timeFormat")(item.duration))+")\n                ")])])])]):_vm._e()})],2),_c('div',{staticClass:"vi-basket-total"},[_c('span'),_c('div',{staticClass:"vi-basket-total--label"},[_vm._v("\n            "+_vm._s(_vm.$t("total"))+":\n            "),_c('strong',[_vm._v(_vm._s(_vm._f("priceFormat")(_vm.getTotalServicesPrice,_vm.getPriceFormat, _vm.getChosenServices)))])])]),_c('div',{staticClass:"vi-typography"},[_c('ul',{staticClass:"list-check"},[_c('li',[_vm._v(_vm._s(_vm.$t("you_can_cancel_via_account")))]),_c('li',[_vm._v("\n              "+_vm._s(_vm.$t("we_will_send_you_a_reminder_prior_to_your_appointment"))+"\n            ")])])])],1),_c('div',{staticClass:"vi-booking-created--footer"},[_c('button',{staticClass:"vi-btn btn-primary btn-full mb-3",on:{"click":_vm.goToAppointments}},[_vm._v("\n          "+_vm._s(_vm.$t("view_appointments"))+"\n        ")]),_c('button',{staticClass:"vi-btn btn-white btn-full mb-3",on:{"click":_vm.logout}},[_vm._v("\n          "+_vm._s(_vm.$t("logout"))+"\n        ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="vi-card-accordion">
    <div
      :class="isShow ? null : 'collapsed'"
      :aria-expanded="isShow ? 'true' : 'false'"
      class="vi-card-accordion--heading"
      aria-controls="collapse-5"
      @click="onToggle(id)"
    >
      <h6 class="title-heading">
        {{ title }}
      </h6>
      <i :class="isShow ? 'collapsed' : ''" class="ic-vi-arr-bottom" />
    </div>
    <div class="vi-card-accordion--body">
      <b-collapse id="collapse-5" v-model="isShow">
        <div
          v-for="packageItem in packages"
          :key="packageItem.id"
          :ref="`package${packageItem.id}`"
          :class="packageItem.disabled ? 'pkg_disabled' : ''"
          class="vi-card-accordion--item"
        >
          <div class="vi-card-accordion--item-inner">
            <div
              class="vi-card-accordion--item-inner--toggle"
              @click="
                packageItem.disabled
                  ? ''
                  : selectPackage(packageItem, title, getChosenServices)
              "
            ></div>
            <div class="vi-card-accordion--item-caption-btn">
              <span
                v-if="
                  getChosenServices.find(
                    (item) =>
                      item.package_id && item.package_id === packageItem.id
                  )
                "
                class="vi-btn-add added"
              />
              <span v-else class="vi-btn-add" />
            </div>
            <div class="vi-card-accordion--item-caption">
              <div class="vi-card-accordion--item-caption--head">
                <h5>
                  {{ packageItem.title }} {{ packageItem.disabled }}
                  <span
                    v-if="
                      packageItem.services.some(
                        (i) => i.required_additional_staff === 1
                      )
                    "
                    class="second-staff_info"
                    >{{ $t("2_staff_required") }}</span
                  >
                </h5>
                <span class="vi-price">
                  {{ packageItem.total | formatPrice(getPriceFormat) }}
                </span>
              </div>
              <div class="vi-card-accordion--item-caption--body">
                <div>
                  <p class="vi-card-accordion--item-caption--time">
                    {{
                      packageItem.pricings
                        .map((i) => i.duration)
                        .reduce((a, b) => a + b)
                    }}
                    {{ $t("minute_short") }} |
                    {{ packageItem.services ? packageItem.services.length : 0 }}
                    {{
                      packageItem.services && packageItem.services.length === 1
                        ? $t("service")
                        : $t("services")
                    }}
                  </p>
                  <span
                    class="vi-card-accordion--item-caption--more"
                    @click="showDetails(packageItem)"
                    >{{ $t("show_details") }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { imageWrapper, priceFormatter } from "../../utils/common/common";
import imgArrow from "../../assets/img/arrow-down-round.svg";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "PackageItem",
  filters: {
    formatPrice(amount, format) {
      return priceFormatter(amount, format);
    }
  },
  props: ["id", "title", "isShow", "packages", "choosePackage"],
  data() {
    return {
      imgArrow,
      itemRefs: []
    };
  },
  computed: {
    ...mapGetters(["getServices", "getChosenServices", "getPriceFormat"])
  },
  methods: {
    ...mapMutations(["setIsShowCollapse", "setIsShowVariants"]),
    methodSmallPrice(from, arr, text, type, format) {
      if (type === "hide_price") {
        return text;
      } else {
        const smaller = arr.reduce((a, b) => {
          if (a.price !== 0 && b.price !== 0) {
            if (a.price < b.price) {
              return a;
            }
            return b;
          }
          return a;
        });

        return `${from} ${priceFormatter(smaller.price, format)}`;
      }
    },
    selectPackage(packageItem, title, arr) {
      this.choosePackage(
        packageItem,
        title,
        arr,
        this.$refs[`package${packageItem.id}`][0].offsetTop
      );
    },
    getIdVariant(id, arr) {
      let flag = false;
      arr.map((item) => {
        if (id === item.id) {
          flag = true;
        }
      });
      if (flag) {
        return id;
      }
    },
    imageUrlWrapper(url) {
      return imageWrapper(url);
    },
    onToggle(id) {
      this.setIsShowCollapse(id);
    },
    onToggleVariants(id) {
      this.setIsShowVariants(id);
    },
    showDetails(info) {
      this.$emit("show-details", { ...info, isShow: true, isPackage: true });
    }
  }
};
</script>

<style>
.second-staff_info {
  display: inline-block;
  margin-left: 5px !important;
  color: #7f7f7f;
  font-size: 14px !important;
}

.pkg_disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
